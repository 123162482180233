import { InputText } from 'primereact/inputtext';
// import React, { useState } from 'react';
import { AnnounceComponent } from './Component';
import { Editor, EditorTextChangeParams } from 'primereact/editor';
import { Dropdown } from 'primereact/dropdown';
import ReactQuill, { Quill } from "react-quill";
import axiosInstance from '../../core/axiosInstance';
import { useAppDispatch } from '../../core/hooks';
import { useEffect, useState } from 'react';
import Spinner from '../Spinner/Spinner';

const Font = Quill.import("formats/font");
Font.whitelist = [
  "sans-serif",
  "monospace",
  "serif",
  "times-new-roman",
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida"
];
Quill.register(Font, true);

const Text = (props: any) => {
  // const [text1, setText1] = useState<string>('');
  // const [template_id, setTemplate] = useState(0);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false)
  const [temps, setTemp] = useState([]);
  useEffect(() => {
    axiosInstance.get('/dashboard/templates')
      .then((res: any) => {
        setTemp(res.data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, [])
  let TemplateSelection:any = []
  for (let i = 0; i < temps.length; i++) {
    TemplateSelection.push({ label: temps[i]['name'], value: temps[i]['id'] })
  }
  const renderHeader = () => {
    return (
      <>
        <span className="ql-formats">
          <select className="ql-size">
            <option value="small"></option>
            <option value="medium"></option>
            <option value="large"></option>
            <option value="huge"></option>
          </select>
          <select className="ql-font">
            <option selected value=''></option>
            <option value="monospace"></option>
            <option value="serif"></option>
            <option value="Times-New-Roman">Times New Roman</option>
            <option value="comic-sans">Comic Sans</option>
            <option value="courier-new">Courier New</option>
            <option value="georgia">Georgia</option>
            <option value="helvetica">Helvetica</option>
            <option value="lucida">Lucida</option>
          </select>
        </span>

        <span className="ql-formats">
          <button className="ql-bold" aria-label="Bold"></button>
          <button className="ql-italic" aria-label="Italic"></button>
          <button className="ql-underline" aria-label="Underline"></button>
        </span>



        <span className="ql-formats">
          <select className="ql-color" aria-label="color">
            <option value="red"></option>
            <option value="black"></option>
            <option value="blue"></option>
            <option value="yellow"></option>
          </select>
          <select className="ql-background" aria-label="background">
            <option value="red"></option>
            <option value="black"></option>
            <option value="blue"></option>
            <option value="yellow"></option>
          </select>
        </span>
        <span className="ql-formats">
          <select className="ql-align">
            <option value=''></option>
            <option value="center"></option>
            <option value="right"></option>
            <option value="justify"></option>

          </select>
          <button className="ql-link" aria-label="link"></button>
        </span>
      </>
    );
  }

  const header = renderHeader();
  return (
    <AnnounceComponent className="p-grid">
      <div className="p-col-12 p-grid p-mt-2 p-ml-0">
        <div className="p-col-9">
          <InputText
            type="text"
            required
            placeholder="Title"
            value={props.title}
            autoFocus
            onChange={(e: any) => {
              props.setTitle(e.target.value);
            }}
          />
        </div>
        <div className="p-col-3">
          <Dropdown
            showClear

            value={props.bodytemplate}
            onChange={(e: any) => {
              props.setbodytemplate(e.value);
            }}
            options={TemplateSelection}
            placeholder="select template"
            autoFocus
          />
        </div>
        <div className="p-col-12">
          <Editor
            className="editor"
            style={{ height: '38vh' }}
            value={props.bodyParagraph}
            headerTemplate={header}
            onTextChange={(e: EditorTextChangeParams) => {
              // setText1(e.htmlValue);
              props.setBodyParagraph(e.htmlValue);
              // console.log(e.htmlValue);
            }}
          />
        </div>
      </div>
      {loading ? <Spinner /> : null}
    </AnnounceComponent>
  );
};

export default Text;
