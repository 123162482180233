import { AddAnnounce, Label, BodyContent, ButtonGroup, GoldenButton, GoldenButtonBorder, HeaderContent, MainTitle, } from './CreateAnnounce.style';
import { useAppDispatch, useAppSelector } from '../../../core/hooks';
import axiosInstance from '../../../core/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { useEffect, useRef, useState } from 'react';
import Image from '../../../components/AnnouncementComponents/Image';
import Video from '../../../components/AnnouncementComponents/Video';
import Text from '../../../components/AnnouncementComponents/Text';
import { classNames } from 'primereact/utils';
import moment from 'moment';
import { openPage, selectPerview, } from '../../../features/previewedPage/previewPageSlice';
import { toast } from 'react-toastify';
import Spinner from '../../../components/Spinner/Spinner';
import { openTemp } from '../../../features/previewedPage/previewTempSlice';
import { Dialog } from 'primereact/dialog';
import { DialogBody } from '../Main/Main.style';
import Preview from '../../Preview/Preview';
import CreatePreview from './CreatePreview';

const CreateAnnounce = () => {
  const dispatch = useAppDispatch();
  const page = useAppSelector(selectPerview);
  const [startdate, setstartdate] = useState(new Date());
  const [invalidDates, setInvalidDates] = useState<Date[]>([])
  const [title, setTitle] = useState('');
  const [bodyParagraph, setBodyParagraph] = useState('');
  const [bodytemplate, setbodytemplate] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [images, setImages] = useState([]);
  const [video, setvideo] = useState(null);
  const [imgIds, setImgIds] = useState([]);
  const [method, setMethod] = useState<string>('add');
  const [files, setFiles] = useState<any>([]);
  const [type_id, settype] = useState(0);
  const [endDate, setendDate] = useState(new Date());
  const [showPreview, setShowPreview] = useState(false);
  const [text, setText] = useState('')
  const [prevPage, setPrevPage] = useState({
    page: {
      name: '',
      background: '',
      media: {},
      text: {
        title: '',
        body: '',
        template: {
          id: 0,
          name: '',
          path: '',
        },
      },
      title: '',
      body: '',
      type: 3,
      start_at: '',
      expiry_at: '',
    }
  })
  const submitBtn: any = useRef(null);
  const navigate = useNavigate();
  let formData = new FormData();
  let status = 0;
  const [loading, setLoading] = useState(false)

  const defaultValues: any = {
    name: page.page.name ,
    type_id: page.page.type ,
    start_at: (page.page.start_at ? moment(page.page['start_at']).add(-2, 'h').toDate() : '') ,
    expiry_at: (page.page.expiry_at ? moment(page.page['expiry_at']).add(-2, 'h').toDate() : '') ,
  };

  const getDaysArray = function (start: Date, end: Date) {
    let arr: Date[] = []
    let pageStart = moment()
    let pageEnd = moment()
    
    if (page.page.id) {
      pageStart = moment(page.page['start_at']);
      pageEnd = moment(page.page['expiry_at']);
    }

    const startDay = moment(start)
    let xdate = startDay.clone();
    let days = end.valueOf() - start.valueOf()
    // days = Math.ceil((days / 1000 / 60 / 60) / 24)
    days = moment(end).date() - moment(start).date() + 1
    
    for (let index = 1; index <= days; index++) {
      if (page.page.id && (xdate.isSame(pageStart) || xdate.isSame(pageEnd))) {
        continue
      } else {
        arr.push(xdate.toDate())
        xdate.add(1, 'day')
      }
    }
    return arr;
  };

  const getEvents = async () => {
    let arrDates: any[] = [];
    const calenderEvents: any = await axiosInstance.get('dashboard/pages/published/dates');
    for (const event of calenderEvents.data) {
      const start = new Date(event.start_at)
      const end = new Date(event.expiry_at)
      let internalArr: any = []
      if (method === 'add') {
        internalArr = getDaysArray(start, end)
      }
      arrDates = [...arrDates, ...internalArr]
    }
    setInvalidDates(arrDates)
  }

  useEffect(() => {
    if (page.page.id) {
      setMethod('edit');
      for (const key in defaultValues) {
        if (key === 'start_at' || key === 'expiry_at') {
          const date = moment(page.page[key]).add(-2, 'h').toDate()
          defaultValues[key] = date
          console.log(defaultValues)
        } else {
          defaultValues[key] = page.page[key];
        }
      }
      if (page.page.text) {
        settype(3);
        setTitle(page.page.text.title);
        setBodyParagraph(page.page.text.body);
        setbodytemplate(page.page.text.template.id);
      }
      if (page.page.media.length > 0 && page.page.media[0].type_id === 2) {
        setVideoLink(page.page.media[0].url);
        settype(2);
      }
      if (page.page.media.length > 0 && page.page.media[0].type_id === 1) {
        setImages(page.page.media);
        const ids = page.page.media.map((id: any) => {
          return id.id;
        });
        setImgIds(ids);
        settype(1);
      }
    }
    getEvents()
    return () => {
      dispatch(openPage({ page: {} }));
    };
  }, []);

  const { control, formState: { errors }, handleSubmit, } = useForm({ defaultValues });

  const TypeSelection = [{ label: 'images', value: 1 }, { label: 'video', value: 2 }, { label: 'text', value: 3 },];

  let today = new Date();
  let minDate = new Date();
  let maxDate = new Date();
  minDate.setTime(today.getTime());
  minDate.setDate(today.getDate());
  minDate.setMonth(today.getMonth());
  minDate.setFullYear(today.getFullYear());
  maxDate.setMonth(today.getMonth() + 1);
  maxDate.setFullYear(today.getFullYear() + 1);

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      let requestData: any;
      for (const key in data) {
        const element = data[key];
        if (key === 'start_at' || key === 'expiry_at') {
          console.log('ele', element)
          let newEle: any = moment(element).format('MMM DD YYYY HH:mm:ss ');
          let newDate = newEle.toLowerCase();
          // newDate += ' GMT+0200';
          formData.append(key, newDate);
        } else {
          formData.append(key, element);
        }
      }
      if (method === 'edit') {
        formData.append('_method', 'put');
        if (type_id === 1) {
          for (const id of imgIds) {
            formData.append('images_ids[]', `${id}`);
          }
        }
      }
      formData.append('status', `${status}`);

      switch (type_id) {
        case 1:
          if (files.length <= 0 && imgIds.length <= 0) {
            setLoading(false);
            toast.error('You must select at least 1 image', { position: toast.POSITION.TOP_CENTER, })
            formData = new FormData()
            return;
          }
          for (const file of files) {
            formData.append(`content[]`, file);
          }
          requestData = formData;
          break;
        case 2:
          if (videoLink?.match(/https?:\/\/(www\.)?(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/)) {
            formData.append('content', videoLink);
          } else if (video) {
            formData.append('content', video)
          } else {
            toast.error('The video must be provided as an URL Link (https://vimeo.com/:id) or a File', { position: toast.POSITION.TOP_CENTER, })
            setLoading(false);
            return;
          }
          requestData = formData;
          break;
        case 3:
          formData.append('title', title? title:'')
          if (!bodyParagraph || bodyParagraph.length <= 0) {
            setLoading(false);
            toast.error('The text body field is required ', { position: toast.POSITION.TOP_CENTER })
            return
          }
          formData.append('body', bodyParagraph);
          if (!bodytemplate || bodytemplate.length <= 0) {
            setLoading(false);
            toast.error('You must select a template', { position: toast.POSITION.TOP_CENTER })
            return
          }
          formData.append('template_id', bodytemplate);
          requestData = formData;
          break;
        default:
          break;
      }
      const perfix = method === 'add' ? '/dashboard/pages' : `/dashboard/pages/${page.page.id}`;
      console.log(perfix, requestData)
      const res = await axiosInstance.post<any>(perfix, requestData);
      console.log('res', res)
      setLoading(false);
      toast.success('Your announcement has been set successfully.', { position: toast.POSITION.TOP_CENTER, });
      navigate('/');
    } catch (err: any) {
      setLoading(false);
      console.log('we have error', err)
      formData = new FormData();
    }
  };

  const getFormErrorMessage = (name: string) => {
    return (errors[`${name}`] && (<small className="p-error">{errors ? errors[`${name}`]?.message : null}</small>));
  };

  const previewAnnouncement = async () => {
    let newPrevPage = {...prevPage}
    newPrevPage.page.type = type_id
    await axiosInstance.get('dashboard/media/getactive')
    .then((res : any) => {
      newPrevPage.page.background = res.data.data.url
    })
    if(type_id === 1){
      let arrOfImgs : any = document.getElementById('getImages')?.childNodes
      let arrOfUrls = []
      for (const item of arrOfImgs) {
        arrOfUrls.push(item.src)
      }
      
      newPrevPage.page.media = arrOfUrls
    } else if(type_id === 2){
      console.log(videoLink);
      newPrevPage.page.media = [videoLink]
    } else if(type_id === 3){
      newPrevPage.page.text.title = title
      newPrevPage.page.text.body = bodyParagraph
      if(bodytemplate){
        await axiosInstance.get(`dashboard/template/find/${bodytemplate}`)
        .then((res : any) => {
          console.log(res.data.data.path);
          newPrevPage.page.text.template.path = res.data.data.path
        })
      }
    }
    
    setPrevPage(newPrevPage)
    setShowPreview(true)
    // dispatch(openPage({prevPage}));
  }

  return (
    <AddAnnounce>
      <HeaderContent>
        <MainTitle title="HR Department">{ }</MainTitle>
        <ButtonGroup>
          <GoldenButton type="submit" onClick={() => { status = 1; submitBtn.current.click(); }} > publish </GoldenButton>
          <GoldenButtonBorder onClick={() => { status = 0; submitBtn.current.click(); }} > save without puplish </GoldenButtonBorder>
          <GoldenButton onClick={previewAnnouncement} > View </GoldenButton>
        </ButtonGroup>
      </HeaderContent>

      <BodyContent>
        <h3>New Announcement</h3>
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <button ref={submitBtn} type="submit" hidden></button>
          <div className="p-grid">
            <div className="p-col-12 p-md-6 p-lg-3">
              <Label className={classNames({ 'p-error': errors[`name`], })} htmlFor="name" > Announcement Name </Label>
              <Controller name="name" control={control} rules={{ required: 'Name is required.', pattern:{value:/[^0-9]/, message: "Can't be a number"} }}
                render={({ field, fieldState }) => (
                  <InputText type="text" id={field.name} {...field} placeholder="Enter name"
                    className={classNames({ 'p-invalid': fieldState.invalid, 'p-mt-2': true, })} />)}
              />
              {getFormErrorMessage('name')}
            </div>
            <div className="p-col-12 p-md-6 p-lg-3">
              <Label className={classNames({ 'p-error': errors[`type_id`], })} htmlFor="type_id" > Announcement Type </Label>
              <Controller name="type_id" control={control} rules={{ required: 'Type is required.' }}
                render={({ field, fieldState }) => (
                  <Dropdown id={field.name} value={field.value} options={TypeSelection} placeholder="Enter type" autoFocus
                    onChange={(e) => { settype(e.value); field.onChange(e.value); }}
                    className={classNames({ 'p-invalid': fieldState.invalid, 'p-mt-2': true, })} />)}
              />
              {getFormErrorMessage('type_id')}
            </div>
            <div className="p-col-12 p-md-6 p-lg-3">
              <Label className={classNames({ 'p-error': errors[`start_at`], })} htmlFor="start_at"> {' '} Start date </Label>
              <Controller name="start_at" control={control} rules={{ required: 'Start date is required.' }}
                render={({ field, fieldState }) => (
                  <Calendar
                    id={field.name} value={field.value} showIcon showTime hourFormat="24"
                    onChange={(e: any) => { setstartdate(e.value); field.onChange(e.value); }}
                    className={classNames({ 'p-invalid': fieldState.invalid, 'p-mt-2': true, })}
                    disabledDates={invalidDates} minDate={minDate} maxDate={maxDate}
                    tooltip="The invisible date and time are already blocked. Consider rescheduling."
                  />
                )}
              />
              {getFormErrorMessage('start_at')}
            </div>
            <div className="p-col-12 p-md-6 p-lg-3">
              <Label className={classNames({ 'p-error': errors[`expiry_at`], })} htmlFor="expiry_at" > {' '} End Date  </Label>
              <Controller
                name="expiry_at" control={control} rules={{ required: 'End date is required.' }}
                render={({ field, fieldState }) => (
                  <Calendar id={field.name} className={classNames({ 'p-invalid': fieldState.invalid, 'p-mt-2': true, })}
                    value={field.value} showIcon showTime hourFormat="24" minDate={startdate} maxDate={maxDate} disabledDates={invalidDates}
                    onChange={(e: any) => { setendDate(e.value); field.onChange(e.value); }}
                    tooltip="The invisible date and time are already blocked. Consider rescheduling."
                  />
                )}
              />
              {getFormErrorMessage('expiry_at')}
            </div>
          </div>
          <div className="p-grid">
            {type_id === 1 ? (
              <Image setFiles={setFiles} files={files} setimageids={setImgIds} imgIds={imgIds} />
            ) : type_id === 2 ? (
              <Video setVideoLink={setVideoLink} videoLink={videoLink} video={video} setvideo={setvideo} />
            ) : type_id === 3 ? (
              <Text setTitle={setTitle} setBodyParagraph={setBodyParagraph} setbodytemplate={setbodytemplate}
                title={title} bodyParagraph={bodyParagraph} bodytemplate={bodytemplate} />
            ) : (
              <div></div>
            )}
          </div>
        </form>
        <span style={{ fontSize: '.8vw', position: 'absolute', color: '#5e0000', backgroundColor: '#ffa60045' , bottom: '1vh', left: "2vw" }}>Note: * The invisible date and time are already blocked. Consider rescheduling.</span>
      </BodyContent>
      {showPreview && (
          <Dialog header="This is a preview" visible={true} onHide={() => { setShowPreview(false); }}
            style={{ width: '80vw', height: '90vh', position: 'relative' }} footer={false} modal >
            <DialogBody>
              <CreatePreview data={prevPage.page} />
            </DialogBody>
          </Dialog>
        )}
      {loading ? <Spinner /> : null}
    </AddAnnounce>
  );
};

export default CreateAnnounce;
