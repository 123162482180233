import React, { useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { AnnounceComponent, IframeContainer, UploadBTN } from './Component';
import { Dropdown } from 'primereact/dropdown';
import { toast } from 'react-toastify';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Video = (props: any) => {
  const [type, settype] = useState(props.videoLink ? 1 : 0);
  const TypeSelection = [{ label: 'link', value: 1 }, { label: 'file', value: 2 },];
  const inputFile: any = useRef(null);

  const browseVideoFile = (e: any) => {
    if (e.target.files[0]?.size > 1 * 1024 ** 3) {
      toast.error(`Max file size is 100 mb. You have exceeded`, {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      console.log(e.target.files[0])
      props.setvideo(e.target.files[0] ? e.target.files[0] : '');
    }
  }

  return (
    <AnnounceComponent className='p-pt-2' >
      <div className="p-grid p-m-0">
        <div className="p-col-3">
          <Dropdown
            value={type}
            options={TypeSelection}
            placeholder="Enter type"
            autoFocus
            onChange={(e) => { settype(e.value); }}
          />
        </div>
      </div>

      {
        type === 1 &&
        <InputText type="text" placeholder="Video link here *" autoFocus className='p-m-2 width98'
          value={props.videoLink ? props.videoLink : ''}
          onChange={(e) => {
            props.setVideoLink(e.target.value);
            props.setvideo(null)
          }} />
      }
      {
        type === 2 &&
        <div className="p-grid">
          <div className="p-col-3 p-m-2">
            <UploadBTN type="button" onClick={() => { inputFile.current.click(); }}>
              Upload
              <input autoFocus multiple={false} hidden type="file" ref={inputFile} accept="video/*"
                onChange={(e) => {
                  browseVideoFile(e);
                  props.setVideoLink(null);
                  e.target.value = ''
                }} />
            </UploadBTN>
          </div>

          <IframeContainer className="p-col-6 p-mt-xl-6 p-mt-md-4">
            {props.video &&
              <FontAwesomeIcon icon={faTrash} className="favIcon" onClick={()=>{ props.setvideo('') }} />
            }
            <iframe
              title="video_frame"
              src={props.video ? URL.createObjectURL(props.video) : ''}
              height="100%" width="100%"
              frameBorder={0}
              allowFullScreen
              style={{ height: '40vh', borderRadius: '15px', maxWidth: '100vh' }}
            />
          </IframeContainer>

        </div>
      }
    </AnnounceComponent>
  );
};

export default Video;
