import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../core/store';

export interface IPageState {

 page:any
}

const initialState: IPageState = {
    page:{}
};

export const perviewPageSlice = createSlice({
  name: 'perviewPage',
  initialState,

  reducers: {
    openPage: (state, actions) => {
        // console.log('actions',actions.payload)
    //  state.isOpen = actions.payload.isOpen
     state.page = actions.payload.page
    },
  },
});

export const { openPage } = perviewPageSlice.actions;

export const selectPerview = (state: RootState) => state.perviewPage;

export default perviewPageSlice.reducer;
