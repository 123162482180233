import styled, { keyframes } from 'styled-components';
const enterFromAbove = keyframes`
  0%{
    transform:translate(0,-1rem);
    opacity:0;
  }
  100%{
    transform:translate(0px,0px);
    opacity:1;
    
  }
`;

export const AnnounceComponent = styled.div`
  width: 100%;
  animation: 0.5s ${enterFromAbove} ease-in-out;

  .editor {
  }

  .p-divider {
    margin: 0.4rem 0px !important;
  }
  .width98{
    width: 98% !important;
  }
`;

export const ImageContainer = styled.div`
  height: 53vh;
  position: relative;
  @media only screen and (min-width: 1450px){
    height: 57vh;
  }

  > h3 {
    font-weight: 400;
    margin: 0.4rem 0px 0px;
  }

  > .p-divider {
    margin: 0.4rem 0px !important;
  }

  .main_image {
    position: absolute;
    z-index: 10;
    top: 50px;
    left: 9px;
    width: 72vh;
    max-width: 98%;
    height: 41vh;
    @media only screen and (min-width: 1300px){
      width: 95vh;
      height: 49vh;
      max-width: 100%;
    }
  }

  .favIcon {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 100;
    color: #ff5656;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
  }

  button {
    color: #fff;
    text-transform: capitalize;
    font-weight: bold;
    width: 15%;
    height: 7%;
    cursor: pointer;
    background-color: var(--primary-color);;
    border: 0;
    border-radius: 25px;
    position: absolute;
    bottom: -2px;
    left: 45%;
    transform: translate(-50%, 0);
    @media only screen and (min-width: 1300px){
      bottom: -55px;
    }
  }
`;

export const DroppableArea = styled.div`
  position: absolute;
  top: 50px;
  left: 9px;
  width: 80%;
  height: 40vh;
  border: 1px #d1d1d1 dashed;
`;

export const UploadedFilesTitle = styled.p`
  font-size: 12px;
  font-weight: 400;
  margin: 0.4rem 0px 0.9rem;
`;

export const UploadedFilesImage = styled.img`
  margin: 2px;
  width: 150px;
  height: 88px;
  border-radius: 5px;
  cursor: pointer;
  @media only screen and (min-width: 1450px){
    width: 230px;
    height: 135px;
  }

  > h3 {
    font-weight: 400;
    margin: 0.4rem 0px 0px;
  }
`;

export const UploadedFilesContainer = styled.div`
  overflow: hidden;
  overflow-y: auto;
  height: 42vh;
  @media only screen and (min-width: 1450px){
    height: 46vh;
  }
`;

export const UploadBTN = styled.button`
  padding: 0.5rem 1rem;
  border: solid 2px var(--primary-color);
  color: var(--primary-color);
  font-weight: bolder;
  border-radius: 5px;
  background-color: #FFF;
`;

export const IframeContainer = styled.div`
  position: relative;
  .favIcon {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 100;
    color: #ff5656;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
  }
`