import React from 'react';
import { CardTable, CardHeader } from './Card.style';
import Filter from '../Filter/Filter';
import Table from '../Table/Table';

const Card = (props: any) => {
  return (
    <CardTable>
      <CardHeader>
        {props.hasFilter ? <Filter /> : null}
      </CardHeader>
      <Table
        cols={props.cols}
        data={props.data}
        actions={props.actions}
        totalRecode={props.totalRecode}
        nextLink={props.nextLink}
        firstLink={props.firstLink}
        nextLast={props.nextLast}
        nextPrev={props.nextPrev}
        setactions={props.setactions}
        setPerfix={props.setPerfix}
      />
    </CardTable>
  );
};

export default Card;
