import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../core/store';

export interface LoadingSpinner {
  loading: boolean;
}

const initialState: LoadingSpinner = {
  loading: false,
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,

  reducers: {
    setLoading: (state, actions) => {
      state.loading = actions.payload;
    },
  },
});

export const { setLoading } = loadingSlice.actions;

export const loadingSelector = (state: RootState) => state.loading.loading;

export default loadingSlice.reducer;
