import React, { useEffect, useRef, useState } from 'react';
import { Divider } from 'primereact/divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../core/hooks';
import { selectPerview } from '../../../features/previewedPage/previewPageSlice';
import { toast } from 'react-toastify';
import { AnnounceComponent, DroppableArea, ImageContainer } from '../../../components/AnnouncementComponents/Component';
let counter = -1;
const TempImage = (props: any) => {
//   const page = useAppSelector(selectPerview);
  const [indicator, setindicator] = useState(0); // define the deletion index
  let [files, setfiles] = useState<any>();
  const [imageURL, setimageURL] = useState('');
  const inputFile: any = useRef(null);
  const ext = ['jpg', 'jpeg', 'png']

  useEffect(() => {
    if (props.old) {
      setimageURL(props.old)
    }
  }, []);

  const uploadTemp = (e: any) => {
      const element = e.target.files[0]
      console.log(element);
      
      
    if (element > 4000000) {
        toast.error(`You have exceeded the limit. Maximum file size should be 4 MB. `, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (!ext.includes(element.name.split('.').pop())) {
        toast.error(`Please enter a valid image type.`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      else {
        if(element){
            setfiles(() => URL.createObjectURL(element));
            props.setFiles(element);
            setimageURL(URL.createObjectURL(element));
        }
        setindicator(counter)
      }
    }

  const deleteMainImage = () => {
    setimageURL('');
    
    props.setFiles((files: any) => {
      return [];
    });
    let ids = props.imgIds;
    props.setimageids(ids);
  };

  return (
    <AnnounceComponent className="p-grid p-mt-2 p-ml-2">
      <ImageContainer className="p-col-12 p-md-9 p-xl-8">
        <h3>Upload Image</h3>
        <Divider layout="horizontal" />
        {imageURL ? (
          <div className="main_image">
            <FontAwesomeIcon
              icon={faTrash}
              className="favIcon"
              onClick={deleteMainImage}
            />
            <img src={imageURL} alt="uploaded file" width="100%" height="100%" />
          </div>
        ) : (
          <DroppableArea></DroppableArea>
        )}
        <button
          type="button"
          onClick={() => {
            inputFile.current.click();
          }}
        >
          Upload
          <input
            hidden
            type="file"
            ref={inputFile}
            accept="image/*"
            onChange={uploadTemp}
          />
        </button>
      </ImageContainer>
    </AnnounceComponent>
  );
};

export default TempImage;
