import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import React, { useEffect, useState } from 'react'
import Countdown from 'react-countdown';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from '../../../core/axiosInstance';
import { useAppDispatch } from '../../../core/hooks';
import { setLoading } from '../../../features/loading/loadingSlice';
import { openTemp } from '../../../features/previewedPage/previewTempSlice';
import { BodyContent, CellContent, HeaderBar, MainTitle } from '../Main/Main.style';

const Template = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [temps, setTemp] = useState([]);
  let selectedEle: any = {};

  const getStatus: any = (obj: any) => {
    const daysBeforstart = new Date(obj.start_at);
    const startAt = moment(daysBeforstart).add(-2, 'h');
    const daysBeforEnd = new Date(obj.expiry_at);
    const endAt = moment(daysBeforEnd).add(-2, 'h');

    if (obj.status === 1) {
      if (moment().isBetween(startAt, endAt)) return 'Published'
      if (startAt.isAfter(moment())) return 'Pending'
      if (endAt.isBefore(moment())) return 'Expired';
    } else {
      if (endAt.isBefore(moment())) return 'Expired'
      else {
        // console.log(moment() , endAt ,obj.name)
        return 'Draft'
      }
    }
  };

  useEffect(() => {
    axiosInstance.get('/dashboard/templates')
      .then((res: any) => {
        setTemp(res.data.data)
        dispatch(setLoading(false))
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false))
      });
  }, []);

  const deletePage = async (id: number, i: number) => {
    dispatch(setLoading(true))
    await axiosInstance
      .delete(`/dashboard/template/delete/${id}`)
      .then((res: any) => {
        console.log(res);
        
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        let newTemps = [...temps];
        newTemps.splice(i, 1);
        setTemp(newTemps);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
      });
    selectedEle = {};
  };

  const accept = (): any => {
    deletePage(selectedEle.id, selectedEle.i);
  };

  const reject = () => {
    // console.log('rejected');
  };

  const confirmDelete = (message: string) => {
    confirmDialog({
      message,
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept,
      reject,
    });
  };

    return (
        <>
        <HeaderBar>
          <MainTitle>
            Manage Templates
          </MainTitle>
          <Button onClick={() => navigate('/add-template')} label="Add Template" className="p-button-raised golden-btn" />
        </HeaderBar>
        <BodyContent>
          <HeaderBar>
            <h3>All Available Templates</h3>
          </HeaderBar>
          <div className="p-grid">
          {temps.map((temp: any, i) => {
            return (
              // announcement card
              <CellContent className="p-col-6 p-lg-4 p-xl-3" key={i}>
                <img src={temp.path} alt="background" />
                <div className="overlay"></div>
                <div className="controllBar">
                  <p>
                    {temp.name}
                  </p>
                  <div className="p-d-flex">
                    <FontAwesomeIcon icon={faPen} className="favIcon" title="Edit"
                      onClick={() => {
                        dispatch(openTemp({ temp }));
                        navigate('/add-template');
                      }}
                    />
                    <FontAwesomeIcon icon={faTrash} className="favIcon red" title="Delete"
                      onClick={async () => {
                        selectedEle.id = temp.id;
                        selectedEle.i = i;
                        confirmDelete('Are you sure you want to delete this announcement');
                      }}
                    />
                  </div>
                </div>
              </CellContent>
            );
          })}
        </div>
        </BodyContent>
        </>
    );
}

export default Template;