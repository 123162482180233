import axios from 'axios';

const axiosInstance = axios.create({
  baseURL:  process.env.REACT_APP_DATABASE_URL,
});
// axiosInstance.interceptors.request.use(function (config: any) {
//   const token = localStorage.getItem('userToken');
//   config.headers.Authorization = token ? `Bearer ${token}` : '';
//   return config;
// });

export default axiosInstance;
