import styled from 'styled-components';

export const PreviewContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img{
        height: 100%;
        width: 100%;
        position: absolute;
        filter: brightness(.4);
    }
`;

export const TextContainer = styled.div`
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    min-width: 15rem;
    z-index: 8;
    background-color: #000;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding: 10rem 3rem 2rem;
    .header{
        text-transform: uppercase;
        font-size: 5vh;
        text-align: left;
        font-weight: bold;
        color: #AE8644;
        text-shadow: -1px 0px 1px rgb(97, 100, 5);
        filter: brightness(1.1);
        font-family: serif;
        padding-right: 60%;
    }
    .t-body{
        height: 80%;
        padding-right: 60%;
        overflow: hidden;
        overflow-y: auto;
        word-break: break-word;
        *{
            color: #ffffff !important;
        }
        
        .p-dialog .p-dialog-header {
            padding: 1rem !important;
            }

            .ql-align-center {
            text-align: center;
            }
            p.ql-align-right {
            text-align: right !important;
            }
            .ql-font-monospace {
            font-family: monospace;
            }
            .ql-font-serif {
            font-family: serif;
            }
            .ql-size-huge {
            font-size: 4vh;
            }
            .ql-size-large {
            font-size: 3vh;
            }
            .ql-size-small {
            font-size: 1vh;
            }

    }
    img{
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 9;
        border-radius: 15px;
        filter: brightness(1);
    }
    div{
        z-index: 10;
    }
`

export const MediaContainer = styled.div`
    top: 12%;
    left: 13%;
    height: 75%;
    position: absolute;
    width: 74%;
    max-width: 90%;
`
export const Slider = styled.div`
    div > img{
        height: 82vh !important;
        max-width: 100% !important;
        margin-top: 3vh;
    }
    .image-gallery-thumbnails .image-gallery-thumbnails-container{
        position: absolute;
        bottom: -40px ;
        left: 50%;
        transform: translate(-50%, 0px) !important;
        .image-gallery-thumbnail .image-gallery-thumbnail-image{
            width: 80px;
            line-height: 0;
            max-height: 45px;
        }
    }
    .image-gallery-icon.image-gallery-play-button,
    .image-gallery-icon.image-gallery-fullscreen-button
    {
        display: none;
    }
`

export const ImgBC = styled.img`
    position: absolute;
    height: 100% ;
    width: 100% ;
    top: 0px;
    left: 0px;
    filter: brightness(.4);
`