import { Notfound, NotfoundH1, NotfoundLink, NotfoundP, Warper } from './NotFound.style';

const NotFound = () => {
  return (
    <Warper>
      <Notfound>
        <div>
          <NotfoundH1>page not found</NotfoundH1>
        </div>
        <NotfoundP>
          We 're sorry, the page you required could not be found<br/>
          Please, go back to the home page
         </NotfoundP>
        <NotfoundLink href="/">go home</NotfoundLink>
      </Notfound>
    </Warper>
  )
}
export default NotFound;
