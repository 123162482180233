import React, { useEffect, useRef, useState } from 'react';
import { AnnounceComponent, DroppableArea, ImageContainer, UploadedFilesContainer,
  UploadedFilesImage, UploadedFilesTitle, } from './Component';
import { Divider } from 'primereact/divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../core/hooks';
import { selectPerview } from '../../features/previewedPage/previewPageSlice';
import { toast } from 'react-toastify';
let counter = -1;
const Image = (props: any) => {
  const page = useAppSelector(selectPerview);
  const [indicator, setindicator] = useState(0); // define the deletion index
  let [files, setfiles] = useState<any[]>([]);
  const [imageURL, setimageURL] = useState('');
  const inputFile: any = useRef(null);
  const ext = ['jpg', 'jpeg', 'png']

  useEffect(() => {
    if (page.page.id && page.page.media[0]?.type_id === 1) {
      const arrImages = page.page.media.map((img: any) => {
        return img.url;
      });
      setfiles(arrImages);
      setimageURL(arrImages[0])
      setindicator(0)
      // console.log('files', files);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadMultiFiles = (e: any) => {
    if ((e.target.files.length + files.length) <= 5) {
      for (const element of e.target.files) {
        if (element.size > 4000000) {
          toast.error(`You have exceeded the limit. Maximum file size should be 4 MB. `, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (!ext.includes(element.name.split('.').pop())) {
          toast.error(`Please enter a valid image type.`, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
        else {
          setfiles((files) => [...files, URL.createObjectURL(element)]);
          props.setFiles((files: any) => [...files, element]);
          setimageURL(URL.createObjectURL(element));
          counter = counter + 1;
          console.log(counter);
          setindicator(counter)
        }
      }
    }
    else {
      toast.error(`Sorry, you can't upload more than 5 images`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    e.target.value = '';
  };

  const deleteMainImage = () => {
    let VirtualArray = files;
    console.log(VirtualArray)
    VirtualArray.splice(indicator, 1);
    console.log("indicator: ", indicator)
    setfiles(VirtualArray);
    counter = counter - 1;
    setimageURL(files[0] ? files[0] : '');
    setindicator(0);
    props.setFiles((files: any) => {
      let arr: any[] = files;
      // console.log(arr);
      arr.splice(indicator, 1);
      return arr;
    });
    let ids = props.imgIds;
    ids.splice(indicator, 1);
    props.setimageids(ids);
  };

  return (
    <AnnounceComponent className="p-grid p-mt-2 p-ml-2">
      <ImageContainer className="p-col-12 p-md-9 p-xl-8">
        <h3>Upload Image</h3>
        <Divider layout="horizontal" />
        {imageURL ? (
          <div className="main_image">
            <FontAwesomeIcon
              icon={faTrash}
              className="favIcon"
              onClick={deleteMainImage}
            />
            <img src={imageURL} alt="uploaded file" width="100%" height="100%" />
          </div>
        ) : (
          <DroppableArea></DroppableArea>
        )}
        <button
          type="button"
          onClick={() => {
            inputFile.current.click();
          }}
        >
          Upload
          <input
            multiple
            hidden
            type="file"
            ref={inputFile}
            accept="image/*"
            onChange={uploadMultiFiles}
          />
        </button>
      </ImageContainer>
      <div className="p-col-12 p-md-3 p-xl-4">
        <UploadedFilesTitle>Uploaded files</UploadedFilesTitle>
        <Divider layout="horizontal" />
        <UploadedFilesContainer>
          <div id='getImages'>
          {files[0] &&
            files.map((image, i) => {
              return (
                <UploadedFilesImage
                  src={image}
                  alt="element"
                  key={i}
                  onClick={() => {
                    setimageURL(files[i]);
                    setindicator(i); //deleting index
                  }}
                />
              );
            })}
          </div>
        </UploadedFilesContainer>
      </div>
    </AnnounceComponent>
  );
};

export default Image;
