import styled, { keyframes } from 'styled-components';
const enterFromLeft = keyframes`
  0%{
    position:fixed;
    right:-100%;
    opacity: 0;
    width: calc( 100vw - 12rem);
    /* left: 200%; */
    
  }
  100%{
    /* position: absolute; */
    position:fixed;
    opacity: 1;
    right: 0;
    width: calc( 100vw - 12rem);
  }
`;

export const AddAnnounce = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  animation: 1s ${enterFromLeft};
`;

export const MainTitle = styled.h2`
  font-size: 1.6rem;
  font-weight: 500;
  margin: 1rem 0px;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  /* justify-content: space-between; */
  align-items: center;
  /* position: fixed; */
  top: 3rem;
  right: 1rem;
  /* max-width: 40%; */
  > button {
    border-radius: 5px;
    border: solid 2px var(--primary-color);;
    text-transform: capitalize;
    font-weight: bold;
    width: 10rem;
    height: 2.5rem;
    margin: 0.2rem;
    cursor: pointer;
    border-radius: 5px;
    border: solid 2px var(--primary-color);;
    text-transform: capitalize;
    font-weight: bold;
    width: 10rem;
    height: 2.5rem;
    margin: 0.2rem;
    cursor: pointer;
  }
`;

export const GoldenButton = styled.button`
  background-color: var(--primary-color);;
  color: #fff;
`;

export const GoldenButtonBorder = styled.button`
  color: var(--primary-color);;
  background-color: #fff;
`;

export const BodyContent = styled.div`
  position: relative;
  background-color: #fff;
  height: 78vh;
  border-radius: 20px;
  border: solid lightgrey 2px;
  padding: 2%;
  @media only screen and (min-width: 1366px){
    height: 85vh;
  }
  > h3 {
    font-weight: 700;
    margin: 0.5rem 0px 0.7rem;
  }
`;
export const Label = styled.label`
  font-size: 12px;
  font-weight: 400;
`;
