import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './layout/Login/Login';
import './App.css';
import { selectAuth } from './features/auth/authSlice';
import { useAppSelector } from './core/hooks';
import NotFound from './layout/NotFound/NotFound';
import 'primeflex/primeflex.css';
import Spinner from './components/Spinner/Spinner';
import ForgotPassword from './layout/ForgotPassword/ForgotPassword';
import ResetPassword from './layout/ForgotPassword/ResetPassword';
import LogHistory from './layout/Home/LogHistory/LogHistory';
import CreateAnnounce from './layout/Home/CreateAnnounce/CreateAnnounce';
import Preview from './layout/Preview/Preview';
import Calender from './layout/Home/Calender/Calender';
import { ToastContainer } from 'react-toastify';
import Background from './layout/Home/Background/Background';
import Template from './layout/Home/Template/Template';
import AddTemplate from './layout/Home/Template/AddTemplate';

function App() {
  const auth = useAppSelector(selectAuth);
  const token = localStorage.getItem('userToken');
  const Home = lazy(() => import('./layout/Home/Home'));
  // console.log('auth',auth,token)

  return (
    <Router>
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/forgotPassword" element={<ForgotPassword />}></Route>
          <Route path="/resetPassword" element={<ResetPassword />}></Route>
          <Route path="/pages/:id" element={<Preview />}>
          </Route>
          <Route
            path="/"
            element={!!token || auth.isAuth ? <Home /> : <Navigate to="/login" />}
          >
            <Route path="/loghistory" element={<LogHistory />} />
            <Route path="/background" element={<Background />} />
            <Route path="/calender" element={<Calender />} />
            <Route path="/createAnnounce" element={<CreateAnnounce />} />
            <Route path="/templates" element={<Template />} />
            <Route path="/add-template" element={<AddTemplate />} />
          </Route>
          <Route>pages</Route>
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
        <ToastContainer />
      </Suspense>
    </Router>
  );
}

export default App;
