import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import authReducer from '../features/auth/authSlice';
import selectedColReducer from '../features/selectedCol/selectedColSlice';
import loadingReducer from '../features/loading/loadingSlice';
import dialogeReducer from '../features/dialoge/dialogeSlice';
import perviewPageReducer from '../features/previewedPage/previewPageSlice';
import perviewTempReducer from '../features/previewedPage/previewTempSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    selectCol: selectedColReducer,
    loading: loadingReducer,
    dialoge: dialogeReducer,
    perviewPage: perviewPageReducer,
    perviewTemp: perviewTempReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
