import logo from '../../assets/images/cadLogo.png';
import { ForgotDiv, Warper, Logo, Title, FomrDiv, Label } from './ForgotPassword.style';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { useAppDispatch, useAppSelector } from '../../core/hooks';
import { loadingSelector, setLoading } from '../../features/loading/loadingSlice';
import axiosInstance from '../../core/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';

const ResetPassword = () => {
    const defaultValues = {
        password: '',
        confirmPassword: '',
        email: 'admin@admin.com'
    };

    //selectors
    const isLoading = useAppSelector(loadingSelector);
    const { control, handleSubmit } = useForm({ defaultValues });

    //store actions to store creadentials to the store
    const dispatch = useAppDispatch();

    //navigator to navigate to home if login sucess
    const navigate = useNavigate();

    const onSubmit = async (data: any) => {
        dispatch(setLoading(true));
        if (data.password === data.confirmPassword) {
            await axiosInstance.post<any>('/dashboard/reset-password', data)
                .then(() => {
                    // console.log('success')
                    navigate('/');
                })
                .catch((err: any) => {
                    // console.log(err);
                    dispatch(setLoading(false));
                });
        }
        else {
            // console.log(control)
            dispatch(setLoading(false));
        }
    };

    return (
        <Warper>
            <Logo src={logo} alt="logo" />
            <ForgotDiv>
                <Title>Reset password</Title>
                <FomrDiv>
                    <div className="p-d-flex p-jc-center" style={{ position: 'relative' }}>
                        <div className="card">
                            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                                <div className="p-field">
                                    <Label htmlFor="password">Enter new password</Label>
                                    <Controller
                                        name="password"
                                        control={control}
                                        rules={{
                                            required: 'Password is required.',
                                            minLength: {
                                                value: 6, 
                                                message:'Enter at least 6 digits'}
                                        }}
                                        render={({ field, fieldState }) => (
                                            <Password
                                                disabled={isLoading ? true : false}
                                                id={field.name}
                                                {...field}
                                                placeholder="password"
                                                toggleMask
                                            />
                                        )}
                                    />
                                    {}
                                </div>

                                <div className="p-field">
                                    <Label htmlFor="password">Confirm password</Label>
                                    <Controller
                                        name="confirmPassword"
                                        control={control}
                                        rules={{
                                            required: 'Password is required.',
                                            minLength: {
                                                value: 6, 
                                                message:'Enter at least 6 digits'}
                                        }}
                                        render={({ field, fieldState }) => (
                                            <Password
                                                disabled={isLoading ? true : false}
                                                id={field.name}
                                                {...field}
                                                placeholder="confirm password"
                                                toggleMask
                                            />
                                        )}
                                    />
                                </div>

                                <Button
                                    type="submit"
                                    label="Send Link"
                                    className="p-mt-2 submit-btn"
                                    disabled={isLoading ? true : false}
                                >
                                    {isLoading ? (
                                        <ProgressSpinner style={{ height: '1.4rem', textAlign: 'center' }} />
                                    ) : null}
                                </Button>
                            </form>
                        </div>
                    </div>
                </FomrDiv>
            </ForgotDiv>
        </Warper>
    )
}

export default ResetPassword
