import { UserCredentials } from './UserCredentials.interface';
import axiosInstance from '../../core/axiosInstance';

export const login = async (data: UserCredentials) => {
  const res = await axiosInstance.post<any>('/dashboard/login', data);
  return res.data.data;
};

export const logout = async () => {
  const res = await axiosInstance.post<any>('/dashboard/logout', {});
  return res.data.message;
};
