import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Image from '../../../components/AnnouncementComponents/Image';
import axiosInstance from '../../../core/axiosInstance';
import { useAppDispatch, useAppSelector } from '../../../core/hooks';
import { openTemp, selectTempPerview } from '../../../features/previewedPage/previewTempSlice';
import { AddAnnounce, ButtonGroup, GoldenButton, GoldenButtonBorder, HeaderContent, Label } from '../CreateAnnounce/CreateAnnounce.style';
import { BodyContent, MainTitle } from '../Main/Main.style';
import TempImage from './TempImage';

const AddTemplate = () => {
    const dispatch = useAppDispatch();
    const temp = useAppSelector(selectTempPerview);
    const [method, setMethod] = useState<string>('add');
    const [tempImg, setTempImg] = useState([]);
    const [images, setImages] = useState([]);
    const [imgIds, setImgIds] = useState([]);
    const [file, setFiles] = useState<any>([]);
    let navigate = useNavigate()
    let formData = new FormData();
    const submitBtn: any = useRef(null);
    let status = 0;
    const saveTemplates = () => {
        console.log('saved');
    }
    
    const defaultValues: any = {
        name: temp.temp.name,
        image: temp.temp.path,
    };
    useEffect(() => {
        if (temp.temp.id) {
          setMethod('edit');
        }
        return () => {
          dispatch(openTemp({ temp: {} }));
        };
      }, []);
      const onSubmit = async (data: any) => {
        try {
          let requestData: any;
          formData.append('name', data['name'])
          
          if(file.length === 0 && method === 'add'){
              toast.error('Select an Image.', { position: toast.POSITION.TOP_CENTER, })
          } else {
            formData.append(`image`, file);
            requestData = formData;
            
            const prefix = method === 'add' ? '/dashboard/template/add' : `/dashboard/template/edit/${temp.temp.id}`;
            console.log(prefix, requestData)
            const res = await axiosInstance.post<any>(prefix, requestData)
            .then((res: any) => {
                if(res.data.message === "Template name already exists."){
                    toast.error(res.data.message, { position: toast.POSITION.TOP_CENTER, });
                } else {
                    toast.success('Your announcement has been set successfully.', { position: toast.POSITION.TOP_CENTER, });
                    navigate('/templates');
                }
            })
          }
        } catch (err: any) {
          console.log('we have error', err)
          formData = new FormData();
        }
      };
      const { control, formState: { errors }, handleSubmit, } = useForm({ defaultValues });
    return (<>
    <AddAnnounce>
        <HeaderContent>
            <MainTitle title="HR Department">{ }</MainTitle>
            <ButtonGroup>
            <GoldenButtonBorder onClick={() => submitBtn.current.click()} > Save </GoldenButtonBorder>
            </ButtonGroup>
        </HeaderContent>

        <BodyContent>
        <h3>New Announcement</h3>
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <button ref={submitBtn} type="submit" hidden></button>
          <div className="p-grid">
            <div className="p-col-12 p-md-4 p-lg-3 d-flex">
              <Label htmlFor="name" > Template Name </Label>
            </div>
            <div className="p-col-12 p-md-8 p-lg-9">
                <Controller name="name" control={control} rules={{ required: 'Name is required.', pattern:{value:/[^0-9]/, message: "Can't be a number"} }}
                    render={({ field, fieldState }) => (
                    <InputText type="text" id={field.name} {...field} placeholder="Enter name"
                        />)}
                />
            </div>
            
          </div>
          <div className="p-grid">
              <TempImage old={defaultValues.image} setFiles={setFiles} files={file} setimageids={setTempImg} imgIds={tempImg} />
          </div>
        </form>
        <span style={{ fontSize: '.8vw', position: 'absolute', color: '#5e0000', backgroundColor: '#ffa60045' , bottom: '1vh', left: "2vw" }}>Note: * The invisible date and time are already blocked. Consider rescheduling.</span>
      </BodyContent>
    </AddAnnounce>
    </>);
}

export default AddTemplate;