import styled from 'styled-components';
import backgroundImg from '../../assets/images/404.png';

export const Warper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  background: url(${backgroundImg});
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  @media (min-width: 1920px) {
    background-size: 100% 100%;
  }
  background-position: center;
`;

export const Notfound = styled.div`
  position: absolute;
  left: 50%;
  top: 70%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 460px;
  width: 100%;
  text-align: center;
  line-height: 1.4;
`;

export const NotfoundH1 = styled.h1`
  color: var(--primary-color);;
  font-size: calc(35px + 6 * ((100vw - 320px) / 680));
  margin: 0px;
  font-weight: bolder;
  text-transform: capitalize;
`;

export const NotfoundP = styled.p`
  font-family: 'Josefin Sans', sans-serif;
  color: var(--primary-color);;
  font-size: calc(5px + 6 * ((100vw - 320px) / 680));
  font-weight: 400;
  margin: 1rem 0px;
`;

export const NotfoundLink = styled.a`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: capitalize;
  background-color: var(--primary-color);;
  color: #fff;
  border-radius: 25px;
  display: inline-block;
  margin-top: 2rem;
  padding: 1rem 5rem;
  font-weight: 600;
`;
