import { faCheckCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FileUpload } from 'primereact/fileupload';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from '../../../core/axiosInstance';
import { BackgroundContainer, Gallery } from './Background.style';
import { setLoading } from '../../../features/loading/loadingSlice';
import { useAppDispatch } from '../../../core/hooks';

const Background = () => {
  const formData = new FormData();
  const [images, setimages] = useState([]);
  const [selected, setselected] = useState(-1);
  const [up, setUp] = useState(0);
  const [busy, setbusy] = useState(false);
  let fileUpload: FileUpload | null;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  /* Call all uploaded background-images in the database and store them in the images variable*/
  useEffect(() => {
    axiosInstance.get('/dashboard/background')
      .then((res: any) => {
        dispatch(setLoading(false))
        setimages(res.data.data);
      })
      .catch((err) => {
        dispatch(setLoading(false))
      });
  }, [up]);

  /* the custome upload function for the prime-uploader component */
  const myUploader = (event: any) => {
    formData.append('name', event.files[0].name);
    formData.append('type_id', '1');
    formData.append('content[]', event.files[0]);
    setbusy(true)
    axiosInstance.post('/dashboard/background', formData)
      .then((res: any) => {
        setUp(up + 1);
        setbusy(false);
      })
      .catch((err) => {
        setbusy(false);
      });
    fileUpload?.clear();
  };

  /* delete certain image from the list of images in Gallery component */
  const deleteBG = async (id: number, i: number) => {
    dispatch(setLoading(true));
    await axiosInstance.delete(`/dashboard/background/${id}`)
      .then((res: any) => {
        let newImages = [...images];
        newImages.splice(i, 1);
        setimages(newImages);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  /* send the selected image to the back-end then navigates to the main page to enable preview */
  const submitBG = async () => {
    if (selected >= 0) {
      const formUpdate = new FormData();
      formUpdate.append('is_active', '1');
      formUpdate.append('_method', 'put');
      dispatch(setLoading(true));
      await axiosInstance.post(`/dashboard/background/${selected}`, formUpdate)
        .then((res: any) => {
          toast.success('The image has been set.', {
            position: toast.POSITION.TOP_CENTER,
          })
          dispatch(setLoading(false));
          navigate('/createAnnounce');  /* navigates to the main page to enable preview */
        }).catch((err) => {
          dispatch(setLoading(false));
        })
    }
  }

  return (
    <BackgroundContainer>
      <h1> </h1>
      <div className="p-grid">
        {/* ------------- Left section ----------- */}
        <div className="p-col-12 p-lg-6">
          <h4>Upload Background</h4>
          {/* FileUpload is a prime Component for browse and upload has many custome attributes */}
          <FileUpload name="demo[]" customUpload uploadHandler={myUploader} multiple={false}
            accept="image/*" maxFileSize={4000000} disabled={busy} ref={(el) => fileUpload = el} />
        </div>
        {/* ------------- right section ----------- */}
        <Gallery className="p-col-12 p-lg-6">
          <h4>Select the main background form this gallery</h4>
          <button onClick={submitBG} disabled={!(selected >= 0)} title="Choose image first" > Submit </button>
          {/* ----------- list of uploaded images ---------- */}
          <div className="imageContainer">
            {images.map((img: any, i) => {
              return (
                <div className="imageElement" key={i} onClick={() => { setselected(img.id); }}>
                  {/* delete icon in the top right */}
                  <FontAwesomeIcon icon={faTrash} className="favIcon delete"
                    onClick={() => { deleteBG(img.id, i); }}
                  />
                  {/* image preview */}
                  <img src={img.url} alt={img.name} />
                  {(selected === img.id || (img.active === '1' && selected === -1)) && (
                    // icon to view which image is selected
                    <FontAwesomeIcon icon={faCheckCircle} className="favIcon check" />
                  )}
                </div>
              );
            })}
          </div>
        </Gallery>
      </div>
    </BackgroundContainer>
  );
}

export default Background;
