import styled from 'styled-components';
import backgroundImg from '../../assets/images/background.png';

export const Warper = styled.div`
  /* width: 100vw;
  height: 100vh;
  background: url(${backgroundImg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative; */
`;

export const ForgotDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 4rem 2.5rem;
  border-radius: 0.6rem;
  background: rgb(52 52 52);
  box-shadow: 0px 0px 9px 2px #000;
`;

export const Mask = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--bc-color);
  filter: brightness(15);
  opacity: 0.2;
`;

export const Logo = styled.img`
  position: fixed;
  top: 5rem;
  left: 4rem;
  z-index: 3;
  width: 24vw;
`;

export const FomrDiv = styled.div`
  color: #fff;
  z-index: 3;
`;
export const Title = styled.p`
  font-size: 1.8rem;
  font-weight: bold;
  color: #fff;
  z-index: 3;
  margin: 0 auto 2rem;
`;

export const Label = styled.label`
  margin: 10px 60px 9px 4px;
  font-size: 14px;
  font-weight: 400;
`;


export const ForgotPassword = styled.a`
cursor: pointer;
  text-align: right;
  font-size: 14px;
  color: #fff;
  text-decoration:none;
  :hover{
    text-decoration:none;
  }
  :visited{
    color:#000;
    text-decoration:none
  }
  margin-bottom: 1rem;
`

export const FlexBox = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`