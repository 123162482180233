import styled from 'styled-components';

export const ButtonWarper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonIcon = styled.i`
  margin-left: 1rem;
  font-size: 1.35em;
  cursor: pointer;
`;
