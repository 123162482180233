import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../core/store';

export interface SelectedCol {
  selected: any;
}

const initialState: SelectedCol = {
  selected: {},
};

export const selectedColSlice = createSlice({
  name: 'selectedCol',
  initialState,

  reducers: {
    selectCol: (state, actions) => {
      state.selected = actions.payload;
    },

    unSelectCol: (state) => {
      state.selected = initialState;
    },
  },
});

export const { selectCol, unSelectCol } = selectedColSlice.actions;

export const selectedCol = (state: RootState) => state.selectCol;

export default selectedColSlice.reducer;
