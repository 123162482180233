import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../core/store';

export interface OpenDialoge {
  open: boolean;
}

const initialState: OpenDialoge = {
  open: false,
};

export const dialogeSlice = createSlice({
  name: 'dialoge',
  initialState,

  reducers: {
    openDialoge: (state, actions) => {
      state.open = actions.payload;
    },
  },
});

export const { openDialoge } = dialogeSlice.actions;

export const dialogeSelector = (state: RootState) => state.dialoge.open;

export default dialogeSlice.reducer;
