import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../core/store';

export interface ITempState {

 temp:any
}

const initialState: ITempState = {
    temp:{}
};

export const perviewTempSlice = createSlice({
  name: 'perviewTemp',
  initialState,

  reducers: {
    openTemp: (state, actions) => {
        // console.log('actions',actions.payload)
    //  state.isOpen = actions.payload.isOpen
     state.temp = actions.payload.temp
    },
  },
});

export const { openTemp } = perviewTempSlice.actions;

export const selectTempPerview = (state: RootState) => state.perviewTemp;

export default perviewTempSlice.reducer;
