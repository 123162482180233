import React from 'react';
import Loader from 'react-loader-spinner';
import { SpinnerWarper, SpinnerContent } from './Spinner.style';

const Spinner = () => {
  return (
    <SpinnerWarper>
      <SpinnerContent>
        <Loader
          type="Grid"
          color="var(--primary-color)"
          height={100}
          width={100}
          // timeout={3000} //3 secs
        />
      </SpinnerContent>
    </SpinnerWarper>
  );
};

export default Spinner;
