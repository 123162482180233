import styled, { keyframes } from 'styled-components';

const bounceFromUp = keyframes`
  0%{
    
    transform: translateY(-1rem);
    opacity:0
  }
  100%{
    transform: translateY(0);
    opacity:1
  }
`;

export const CardTable = styled.div`
  /* position: absolute; */
  /* top: 125px; */
  background-color: #fff;
  /* overflow-y: auto; */
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 1rem;
  /* width: calc(100vw - 13rem); */
  height: 72vh;
  left: 12rem;
  padding: 1rem 1rem 0px;
  animation: ${bounceFromUp} 1s ease-in-out;
  overflow: hidden;
  overflow-y: auto;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;
